import React from 'react';
import i18next from 'i18next';
import './Settings.sass';



export default function FilterCategories(props) {
    let sortList = [
        {
            value: 2,
            title: i18next.t('Date')
        },
        {
            value: 1,
            title: i18next.t('A-Z')
        },
        {
            value: 3,
            title: i18next.t('Featured first')
        }
    ]

    let togglesList = [
        {
            value: 0,
            title: i18next.t('Grid')
        },
        {
            value: 1,
            title: i18next.t('List')
        }
    ]

    function handleToggleListView(value) {
        props.setListView(value.target.value);
        localStorage.setItem('listView', JSON.stringify(value.target.value));
    }

    function setOrderClick(value){
        props.setOrder(parseInt(value.target.value));
        localStorage.setItem('orderView', JSON.stringify(value.target.value));
    }


    return (
        <div className="settings">
            <h2 className="settings__title">{ i18next.t('Settings') }</h2>
            <div className="settings__buttons">
                <button 
                    className="btn btn-primary"
                    onClick={ () => props.setMobileNav(0) }>
                    { i18next.t('Close') }
                </button>
            </div>
            <div className="settings__categories">
                <div className="form-group row">
                    <span 
                        className="col-5 col-form-label">
                        { i18next.t('Order by') }:
                    </span>
                    <div className="col-7">
                        <select 
                            className="form-control"
                            id="orderBy"
                            value={sortList.filter(item => item.value === parseInt(props.order))[0].value }
                            onChange={ e => setOrderClick(e) }>
                            { sortList.map((item, index) => (
                                <option 
                                    value={ item.value }
                                    key={ index }>
                                    { item.title }
                                </option>
                            )) }
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <span 
                        className="col-5 col-form-label">
                        { i18next.t('Show as') }:
                    </span>
                    <div className="col-7">
                        <select 
                            className="form-control"
                            id="showAs"
                            value={ togglesList.filter(item => item.value === parseInt(props.listView))[0].value }
                            onChange={ e => handleToggleListView(e) }>
                            { togglesList.map((item, index) => (
                                <option 
                                    value={ item.value }
                                    key={ index }>
                                    { item.title }
                                </option>
                            )) }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}