import React, { Component, useContext } from 'react'
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import {stepsDesktop} from './stepsDesktop'
import {stepsMobile} from './stepsMobile'
import 'shepherd.js/dist/css/shepherd.css'
import './Shepherd.sass'
import PropTypes from 'prop-types'

const tourOptions = {
  useModalOverlay: true
};

function Button() {
  const tour = useContext(ShepherdTourContext);

  return (
    <button className="tutorial-button" onClick={tour.start} aria-label="Einführung">
      <span className="tutorial-button__icon">
        <svg>
        <title>Einführung</title>
          <use xlinkHref="svg/bootstrap-icons.svg#question-circle" />
        </svg>
      </span>
      <span className="tutorial-button__description">
        Anleitung
      </span>
    </button>
  );
}

export default class Shepherd extends Component {
  render() {
    return (
      <div className="tutorial">
        <ShepherdTour steps={this.props.type === "desktop" ? stepsDesktop : stepsMobile} tourOptions={tourOptions}>
          <Button />
        </ShepherdTour>
      </div>
    );
  }
}

Shepherd.propTypes = {
    type: PropTypes.string
}