import {
    APP_TITLE,
    APP_SHOW_MORE_FILTERS,
    APP_SET_GROUPS_LIST,
    APP_SET_FORMATS_LIST,
    APP_SET_CARDS_LIST,
    APP_SET_MOBILE_NAV,
    APP_SCREEN_SIZE,
    APP_MODAL_OPENED,
    APP_MODAL_DATA,
    APP_DATA_LOADED,
    APP_CHANGE_VIEW,
    APP_CHANGE_LIST_VIEW,
    APP_SET_SHELVES,
    APP_SHELVES_LOADED,
    APP_SET_HERO_DATA,
    ALL_CARDS_LOADED
} from "./actions";



const defaultState = {
    appDataLoaded: false,
    allCardsLoaded: false,
    shelvesDataLoaded: false,
    title: '',
    showMoreFilters: false,
    groupesList: [],
    cardsList: [],
    screenSize: 0,
    // 0 — mobile
    // 1 — tablet
    // 2 — desktop
    showModal: false,
    modalData: {},
    mainView: 1,
    // 0 — home with sets
    // 1 — groups
    // 2 — category view
    listView: 1,
    formats: [],
    shelves: [],
    mobileNav: 0,
    heroData: null
}
  
export const appReducer = (state = defaultState, action) => {
    switch (action.type) {
        case APP_TITLE:
            return { ...state, title: action.payload };
    
        case APP_SHOW_MORE_FILTERS:
            return { ...state, showMoreFilters: action.payload };
    
        case APP_SET_GROUPS_LIST:
            return { ...state, groupesList: action.payload };
    
        case APP_SET_FORMATS_LIST:
            return { ...state, formats: action.payload };
    
        case APP_SET_CARDS_LIST:
            return { ...state, cardsList: action.payload };

        case APP_SCREEN_SIZE:
            return { ...state, screenSize: action.payload };

        case APP_MODAL_OPENED:
            return { ...state, showModal: action.payload };

        case APP_MODAL_DATA:
            return { ...state, modalData: action.payload };

        case APP_DATA_LOADED:
            return { ...state, appDataLoaded: true };

        case ALL_CARDS_LOADED:
            return { ...state, allCardsLoaded: true };

        case APP_CHANGE_VIEW:
            return { ...state, mainView: action.payload };

        case APP_CHANGE_LIST_VIEW:
            return { ...state, listView: action.payload };

        case APP_SET_SHELVES:
            return { ...state, shelves: action.payload };

        case APP_SHELVES_LOADED:
            return { ...state, shelvesDataLoaded: true };
    
        case APP_SET_MOBILE_NAV:
            return { ...state, mobileNav: action.payload };

        case APP_SET_HERO_DATA:
            return { ...state, heroData: action.payload };

        default: return state;
    }
}