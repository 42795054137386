import React from 'react';
import './Header.sass';
import Logo from './../Logo/LogoContainer';
import MyCollectionDropdown from './../../desktop/MyCollectionDropdown/MyCollectionDropdownContainer';
import inResponsiveSizes from '../../../lib/ui/inResponsiveSizes';
import Shepherd from '../Shepherd/Shepherd';


export default class Header extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            filterOpened: true,
            searchOpened: true
        };
    }

    render(){
        return (
            <header 
                className="header page__header">
                {/* Page block */}
                <Logo
                    class="header__logo"
                    brandName={ this.props.title }
                    screenSize={ this.props.screenSize }
                    groupesList={ this.props.groupesList }
                    clearFilters={ this.props.clearFilters }/>

                {/* Tutorial */}
                { (!inResponsiveSizes(['xs', 'sm'], this.props.screenSize)) ?
                    <Shepherd type="desktop"/> 
                    : 
                    <Shepherd type="mobile" />
                } 
                {/* Collection dropdown block */}
                { (!inResponsiveSizes(['xs', 'sm'], this.props.screenSize)) &&
                    <div className="header__user-collection">
                        <MyCollectionDropdown
                            className="header__dropdown-collection"
                            myCardsCollection={ this.props.myCardsCollection }
                            setMyCardsCollection={ this.props.setMyCardsCollection } />
                    </div>
                }
                {/* Logo block */}
                <span className="logo__mediathek">
                    <a href="https://www.unfallkasse-nrw.de/" target="_blank" rel="noopener noreferrer">
                    { !inResponsiveSizes(['xs', 'sm', 'md'], this.props.screenSize) ?
                        <img
                            src="svg/uk-nrw-logo.svg"
                            alt="Neuheit für Pflege" />
                        :
                        <img
                            src="svg/uk-nrw-logo--symbol.svg"
                            alt="Neuheit für Pflege" />
                    }
                    </a>
                </span>

            </header>
        )
    }
}