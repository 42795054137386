import React from 'react';
import i18next from 'i18next';
import './VideoCard.sass';



export default function VideoCard(props) {

    function onCallModalHandler(e) {
        /** 
         * Call modal window if card is video-card.
         */
        props.setOpenModal(true);
        props.setModalData({
            portal_title: props.portal_title,
            link: props.link,
            is_recommendation: props.is_recommendation,
            content: props.content,
            id: props.id
        });
    }

    return (
        
        <div
            className={`video-card ${props.className} `}
            title={ props.portal_title }>
            <div className="video-card__image-area">
                <span 
                    className="video-card__image-area-link"
                    onClick={ () => onCallModalHandler() }>
                    { props.media_cover ? (
                        <img 
                            className="video-card__image"
                            data-id={ props.id }
                            src={ props.media_cover }
                            alt={ props.portal_title }/>
                    ) : (
                        <span>No image</span>
                    )}
                </span>
                {
                    props.myCardsCollection.includes(props.id) ? 
                    <button 
                        className="video-card__button video-card__button--remove-from-favorites"
                        title={ i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                        onClick={ () => props.removeCard() }><span>&minus;</span></button> :
                    <button 
                        className="video-card__button video-card__button--add-to-favorites"
                        title={ i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                        onClick={ () => props.addCard() }><span>&#43;</span></button>
                }
                { props.is_recommendation === 1 && 
                <span 
                    className="video-card__icon video-card__icon--featured"
                    title={ i18next.t('Featured') }>
                    <svg
                        height="20"
                        width="20">
                        <use
                            xlinkHref="/svg/bootstrap-icons.svg#star-fill" />
                    </svg>
                </span>
                }
                { props.type &&
                    <span
                        className="video-card__type">
                        { props.type.type }
                    </span>
                }
            </div>
            { !props.heroCard &&
                <div 
                    className="video-card__info"
                    onClick={ () => onCallModalHandler() }>
                    <span 
                        className="video-card__title video-card__title--cutted">
                        { props.portal_title }
                    </span>
                </div>
            }
        </div>
    )
}