import React from 'react';
import VideoModal from './VideoModal';
import { connect } from 'react-redux';
import { 
    setOpenModal,
    setModalData
} from '../../../store/app/actions';
import { setMyCardsCollection } from './../../../store/share/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class VideoModalContainer extends React.Component {
    render() {
        return (
            <VideoModal {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showModal: state.app.showModal,
        modalData: state.app.modalData,
        myCardsCollection: state.share.myCardsCollection
    }
}
  
const mapDispatchToProps = {
    setOpenModal,
    setModalData,
    setMyCardsCollection
}
  
export default connect(mapStateToProps, mapDispatchToProps)(VideoModalContainer)