export const stepsMobile = [
    {
      id: 'intro',
      buttons: [
        { 
          action (){
            return this.hide();
          },
          classes: 'btn-secondary',
          text: '&times; ABBRECHEN',
        },
        {
          action(){
            return this.next();  
          },  
          classes: 'btn-primary',
          text: 'TOUR STARTEN',
        },
      ],
      classes: 'shepherd-box',
      highlightClass: 'highlight',
      scrollTo: false,
      arrow: false,
      title: 'Willkommen in der Mediathek!',
      text: ['Hier bekommen Sie eine kleine Einführung in die mobile Benutzung der Mediathek.'],
    },
    {
        id: 'step01',
        buttons: [
          {
            action(){
              return this.next();  
            },  
            classes: 'btn-primary',
            text: 'FORTFAHREN',
          },
        ],
        cancelIcon: {enabled: true, lable: 'Tour beenden'},
        attachTo: { element: '.flip-card__button', on: 'top'},
        scrollTo: {behavior: 'smooth', block: 'center'},
        classes: 'shepherd-box',
        highlightClass: 'highlight',
        title: 'Element hinzufügen',
        text: ['Klicken Sie auf das Symbol (+),um ein Element dem Regal hinzuzufügen. Oder auf (&#10003;) um dieses wieder zu entfernen.'],
      },
      {
        id: 'step02',
        cancelIcon: {enabled: true, lable: 'Tour beenden'},
        attachTo: { element: '.mobile-nav', on: 'top'},
        classes: 'shepherd-box',
        highlightClass: 'highlight',
        title: 'Optionen',
        text: ['Hier finden sie die mobile Navigation.'],
        buttons: [
            {
              action(){
                return this.next();  
              },  
              classes: 'btn-primary',
              text: 'FORTFAHREN',
            },
          ],
      },
      {
        id: 'step03',
        cancelIcon: {enabled: true, lable: 'Tour beenden'},
        attachTo: { element: '.mobile-nav__list-item:first-child', on: 'top'},
        classes: 'shepherd-box',
        highlightClass: 'highlight',
        title: 'Optionen',
        text: ['Mit '
        + '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>'
        + ' können Sie die Mediathek durchsuchen'],
        buttons: [
            {
              action(){
                return this.next();  
              },  
              classes: 'btn-primary',
              text: 'FORTFAHREN',
            },
          ],
      },
      {
        id: 'step04',
        cancelIcon: {enabled: true, lable: 'Tour beenden'},
        attachTo: { element: '.mobile-nav__list-item:nth-child(2)', on: 'top'},
        classes: 'shepherd-box',
        highlightClass: 'highlight',
        title: 'Optionen',
        text: ['Mit '
        + '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16"><path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/></svg>'
        + ' können Sie die Mediathek nach bestimmten Arbeitsbereichen, Themen oder Gefährdungsarten filtern'],
        buttons: [
            {
              action(){
                return this.next();  
              },  
              classes: 'btn-primary',
              text: 'FORTFAHREN',
            },
          ],
      },
      {
        id: 'step05',
        cancelIcon: {enabled: true, lable: 'Tour beenden'},
        attachTo: { element: '.mobile-nav__list-item:nth-child(3)', on: 'top'},
        classes: 'shepherd-box',
        highlightClass: 'highlight',
        title: 'Optionen',
        text: ['Mit '
            + '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16"><path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/></svg>' 
            + ' können Sie die Mediathek Sortieren oder von einer Karten- in eine Listenansicht wechseln.'],
        buttons: [
            {
              action(){
                return this.next();  
              },  
              classes: 'btn-primary',
              text: 'FORTFAHREN',
            },
          ],
      },
      {
        id: 'step05',
        cancelIcon: {enabled: true, lable: 'Tour beenden'},
        attachTo: { element: '.mobile-nav__list-item:last-child', on: 'top'},
        classes: 'shepherd-box',
        highlightClass: 'highlight',
        title: 'Optionen',
        text: ['Haben Sie bereits etwas Ihrem Regal hinzugefügt wird zusätzlich ' 
        + '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-archive-fill" viewBox="0 0 16 16"><path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z"/></svg>' 
        + ' angezeigt. Dort können Sie dann Ihre gespeicherten Elemente sehen.'],
        buttons: [
            {
              action(){
                return this.complete();  
              },  
              classes: 'btn-primary',
              text: 'BEENDEN',
            },
          ],
      },
  ];
