import React from 'react';
import Search from './../../common/Search/SearchContainer';
import FilterCategories from './../../desktop/FilterCategories/FilterCategoriesContainer';
import inResponsiveSizes from './../../../lib/ui/inResponsiveSizes';
import CardsList from '../CardsList/CardsListContainer';
import Card from './../../common/Card/CardContainer';
import orderBy from '../../../lib/array/orderBy';
import './Heroscreen.sass';
import {NavLink} from 'react-router-dom';
import i18next from 'i18next';

export default function Heroscreen(props) {
    function drawMobileCards() {
        if(inResponsiveSizes(['xs', 'sm', 'md'], props.screenSize)) {
            if(inResponsiveSizes(['xs', 'sm'], props.screenSize) && parseInt(props.listView) === 0) {
                return (
                    <>
                    <NavLink
                            className="heroscreen__link"
                            to={`/library`}
                            onClick={() => props.clearFilters()}>
                            {i18next.t('Show all media')}
                    </NavLink>
                    <div className="heroscreen__cards-list">
                        { orderBy(props.hero.repos, props.order).map(card => (
                            <Card 
                                className="heroscreen__card"
                                key={ card.id }
                                card={ card } />
                        ))}
                    </div>
                    </>
                )
            } else {
                return (
                    <>
                        <Search
                                className="heroscreen__search" />
                        <FilterCategories
                                className="heroscreen__categories" />
                        <NavLink
                            className="heroscreen__link"
                            to={`/library`}
                            onClick={() => props.clearFilters()}>
                            {i18next.t('Show all media')}
                        </NavLink>
                        <CardsList 
                            className="shelf-box__list"
                            cards={ props.hero.repos.slice(0, 4) } />
                    </>
                )
            }
        }
    }

    
    return (
        <div className="heroscreen container">
            <div className="heroscreen__content">
                <h1 
                    className="heroscreen__title">{ props.hero.name }</h1>
                <p
                    className="heroscreen__description">
                    { props.hero.content }
                </p>
                { !inResponsiveSizes(['xs', 'sm', 'md'], props.screenSize) &&
                    <>
                        <Search
                            className="heroscreen__search" />
                        <FilterCategories
                            className="heroscreen__categories" />
                        <NavLink
                            className="heroscreen__link"
                            to={`/library`}
                            onClick={() => props.clearFilters()}>
                            {i18next.t('Show all media')}
                        </NavLink>
                    </>
                }
                { drawMobileCards() }
            </div>
            { !inResponsiveSizes(['xs', 'sm', 'md'], props.screenSize) &&
                <>
                    { props.hero.repos.slice(1, 14).map(card => (
                        <Card 
                            className="heroscreen__card-image"
                            key={ card.id }
                            card={ card }
                            heroCard={ true } />
                    ))}
                </>
            }
        </div>
    )
}