import React from 'react';
import { useHistory } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import FilterCategories from '../FilterCategories/FilterCategoriesContainer';
import Settings from '../Settings/SettingsContainer';
import './MobileNav.sass';



export default function MobileNav(props) {
    let history = useHistory()

    function setNavState(id, link = null) {
        props.mobileNav === id ? props.setMobileNav(0) : props.setMobileNav(id);
        if(link) {
            history.push(link);
        }
    }

    return (
        <div className="mobile-nav">
            <CSSTransition
                in={ props.mobileNav === 2 || props.mobileNav === 3 }
                timeout={ 600 }
                classNames="mobile-nav__config-area"
                unmountOnExit >
                <div className="mobile-nav__config-area">
                    { props.mobileNav === 2 &&
                        <FilterCategories />
                    }
                    { props.mobileNav === 3 &&
                        <Settings />
                    }
                </div>
            </CSSTransition>
            <ul className="mobile-nav__list">
                <li
                    className="mobile-nav__list-item">
                    <button
                        className={`mobile-nav__button ${props.mobileNav === 1 ? 'mobile-nav__button--active' : ''}`}
                        onClick={ () => setNavState(1, '/search') }>
                        <svg
                            height="26"
                            width="26">
                            <use
                                xlinkHref="svg/bootstrap-icons.svg#search" />
                        </svg>
                    </button>
                </li>
                <li
                    className="mobile-nav__list-item">
                    <button
                        className={`mobile-nav__button ${props.mobileNav === 2 ? 'mobile-nav__button--active' : ''}`}
                        onClick={ () => setNavState(2, '/library') }>
                        <svg
                            height="26"
                            width="26">
                            <use
                                xlinkHref="svg/bootstrap-icons.svg#funnel-fill" />
                        </svg>
                    </button>
                </li>
                <li
                    className="mobile-nav__list-item">
                    <button
                        className={`mobile-nav__button ${props.mobileNav === 3 ? 'mobile-nav__button--active' : ''}`}
                        onClick={ () => setNavState(3) }>
                        <svg
                            height="26"
                            width="26">
                            <use
                                xlinkHref="svg/bootstrap-icons.svg#gear-fill" />
                        </svg>
                    </button>
                </li>
                { props.myCardsCollection.length !== 0 &&
                    <li
                        className="mobile-nav__list-item">
                        <button
                            className={`mobile-nav__button mobile-nav__button--share ${props.mobileNav === 4 ? 'mobile-nav__button--active' : ''}`}
                            onClick={ () => setNavState(4, '/collection') }>
                            <svg
                                height="26"
                                width="26">
                                <use
                                    xlinkHref="svg/bootstrap-icons.svg#archive-fill" />
                            </svg>
                            <span className="mobile-nav__button-icon">
                                { props.myCardsCollection.length }
                            </span>
                        </button>
                    </li>
                }
            </ul>
        </div>
    )
}