import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import { useEffect } from "react";
import {
    useLocation
} from "react-router-dom";
import CategoryLayout from './../layouts/CategoryContainer';
import FilterLayout from './../layouts/FilterContainer';
import orderBy from './../lib/array/orderBy';
import filterCardsByCategories from './../lib/array/filterCardsByCategories';



function ScrollToTop() {
    const { pathname, search } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname, search]);
  
    return null;
}

export default function FilterPage(props) {
    let query = new URLSearchParams(useLocation().search);
    let formatId = parseInt(query.get("format"));


    if(formatId) {
        function formatData() {
            let data = {...props.formats.filter(format => {
                return format.id === (formatId === 'null' ? null : parseInt(formatId));
            })[0]}

            let cards = props.cardsList;
            cards = orderBy(cards, props.filterOrder);
            cards = filterCardsByCategories(cards, props.categoriesSelected, props.groupesList);
            cards = cards.filter(card => card.format_id === formatId);

            data.repos = cards;
            return data;
        }
        // Category view.
        return (
            <>
            <ScrollToTop />
            <CategoryLayout
                backlinkMarkup={ 
                    <NavLink
                        className="category__backlink home-backlink"
                        to={`/library`}>
                        { i18next.t('Library') }
                    </NavLink>
                 }
                title={ formatData().name }
                cards={ formatData().repos } />
            </>
        )
    } else {
        return (
            <>
            <ScrollToTop />
            <FilterLayout />
            </>
        )

    }
}