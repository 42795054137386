import React from 'react';
import './ListLoader.sass';



export default function ListLoader(props) {
    function checkCardsLimit() {
        switch(props.screenSize) {
            case 'md':
                return [1, 2, 3, 4];
            case 'lg':
                return [1, 2, 3, 4, 5];
            case 'xl':
                return [1, 2, 3, 4, 5, 6];
            default:
                return [1, 2];
        }
    }

    return (
        <ul className={`list-loader ${props.className}`}>
            { checkCardsLimit().map((index) => (
                <li 
                    className="list-loader__placeholder" 
                    key={index}>
                    <div className="list-loader__placeholder-image"></div>
                    <div className="list-loader__placeholder-title"></div>
                </li>
            )) }
        </ul>
    )
}