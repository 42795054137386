import React from 'react';
import i18next from 'i18next';
import inResponsiveSizes from './../../../lib/ui/inResponsiveSizes';



export default function Logo(props) {
    return (
        <button 
            className={`btn btn-danger ${props.className}`}
            onClick={ () => {
                props.clearFilters(props.groupesList);
                props.setMobileNav(0)
            } }>
            { inResponsiveSizes(['xs', 'sm'], props.screenSize) ? i18next.t('Clear') : i18next.t('Clear filters') }
        </button>
    )
}