import React from 'react';



export const RegalDropdownButton = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        className="my-link"
        onClick={(e) => {
        e.preventDefault();
        onClick(e);
        }} >
        <span
            className="my-link__icon">
            <svg
                height="16"
                width="16">
                <use
                    xlinkHref="svg/bootstrap-icons.svg#archive-fill" />
            </svg>
        </span>
        {children}
        <svg
            height="13"
            width="13">
            <use
                xlinkHref="svg/bootstrap-icons.svg#chevron-down" />
        </svg>
    </button>
));