import React from 'react';
import { useEffect } from "react";
import {
    useLocation
} from "react-router-dom";
import DefaultLayout from './../layouts/DefaultContainer';
import CategoryLayout from './../layouts/CategoryContainer';
import ShelvesList from './../components/common/ShelvesList/ShelvesListContainer';
import filterShelfBySlug from './../lib/array/filterShelfBySlug';
import Heroscreen from '../components/common/Heroscreen/HeroscreenContainer';



function ScrollToTop() {
    const { pathname, search } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname, search]);
  
    return null;
}

export default function HomePage(props) {
    // On start page set view to groups.
    // props.setMainView(1);
    // Get query params to understand if format is opened.
    let query = new URLSearchParams(useLocation().search);
    let shelf = query.get("shelf");

    if(props.shelvesDataLoaded && shelf) {
        function shelfData() {
            return filterShelfBySlug(props.shelves, shelf);
        }
        // Shelf view.
        return (
            <>
            <ScrollToTop />
            <CategoryLayout
                title={ shelfData().name }
                content={ shelfData().content }
                cards={ shelfData().repos } />
            </>
        )
    } else {
        return (
            <>
            <ScrollToTop />
            <DefaultLayout
                beforeContent={
                    <>
                        { props.shelvesDataLoaded &&
                            <Heroscreen
                                hero={ props.heroData } />
                        }
                    </>
                }
                content={
                    <>
                        <ShelvesList />
                    </>
                } />
            </>
        )
    }
}