import React from 'react';
import CategoriesCheckboxList from './CategoriesCheckboxList';
import { connect } from 'react-redux';
import { 
    setCurrentCategories
} from '../../../store/filter/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class CategoriesCheckboxListContainer extends React.Component {
    render() {
        return (
            <CategoriesCheckboxList {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        categoriesSelected: state.filter.categoriesSelected,
    }
}
  
const mapDispatchToProps = {
    setCurrentCategories
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesCheckboxListContainer)