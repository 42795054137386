import React from 'react'
import Card from './Card'
import { connect } from 'react-redux';
import { 
    setOpenModal,
    setModalData
} from '../../../store/app/actions';
import { setMyCardsCollection } from '../../../store/share/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class CardContainer extends React.Component {
    render() {
        return (
            <Card {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showModal: state.app.showModal,
        myCardsCollection: state.share.myCardsCollection
    }
}
  
const mapDispatchToProps = {
    setOpenModal,
    setModalData,
    setMyCardsCollection
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CardContainer)