import React from 'react';
import Heroscreen from './Heroscreen';
import { connect } from 'react-redux';
import {
    clearFilters
} from '../../../store/filter/actions';


/**
 * Middleware to leave stupid component and clever.
 */
class HeroscreenContainer extends React.Component {
    render() {
        return (
            <Heroscreen {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listView: state.app.listView,
        screenSize: state.app.screenSize,
        order: state.filter.order
    }
}
  
const mapDispatchToProps = {
    clearFilters
}
  
export default connect(mapStateToProps, mapDispatchToProps)(HeroscreenContainer)