import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import shareLinkGenerator from '../../../lib/share/shareLinkGenerator';
import copyToClipboard from '../../../lib/copy/copyToClipboard';
import { RegalDropdownButton } from './../../../lib/elements/RegalDropdownButton';
import './MyCollectionDropdown.sass'



export default function MyCollectionDropdown(props){
    return (
        <Dropdown
            className={`filter__dropdown btn-group ${props.className} `}
            as={ ButtonGroup }>
            <Dropdown.Toggle 
                as={ RegalDropdownButton }>
                { i18next.t('Your collection') }
            </Dropdown.Toggle>
            { props.myCardsCollection.length <= 0 ?
                <span className="desktop-collection__indicator-hidden">
                </span>
            :
                props.myCardsCollection.length < 100 ?
                    <span className="desktop-collection__indicator">
                        { props.myCardsCollection.length }
                    </span>
                    :
                    <span className="desktop-collection__indicator-big">
                        { props.myCardsCollection.length }
                    </span>
                
            }
            <Dropdown.Menu alignRight>
                <NavLink
                    className="dropdown-item"
                    to="/collection">
                    { i18next.t('Your collection') }
                </NavLink>
                <Dropdown.Item 
                    className="sort__dropdown-menu-item dropdown-item"
                    onClick={ () => copyToClipboard(shareLinkGenerator(props.myCardsCollection)) }>
                    { i18next.t('Copy to clipboard') }
                </Dropdown.Item>
                <Dropdown.Item 
                    className="sort__dropdown-menu-item dropdown-item"
                    onClick={ () => {
                        props.setMyCardsCollection([]);
                        window.localStorage.removeItem('myCardsCollection');
                    } }>
                    { i18next.t('Clear collection') }
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}