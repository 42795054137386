import React from 'react';
import ListLoader from './../ListLoader/ListLoaderContainer';
import './CategoryLoader.sass';



export default function CategoryLoader(props) {

    return (
        <div className="category-loader">
            <div
                className="category-loader__title" />
            <ListLoader 
                className="category-loader__list"/>
        </div>
    )
}