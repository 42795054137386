import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import CardsList from '../CardsList/CardsListContainer';
import Card from '../Card/CardContainer';
import inResponsiveSizes from '../../../lib/ui/inResponsiveSizes';
import './CardsCarousel.sass';



export default class CardsCarousel extends React.Component {
    /**
     * Class CardsCarousel gets category in props and calls API to get all cards (repos)
     * from server and show them as carousel list. Also cuts this array in for equal
     * slides. Can change order accorition to redux state, show all as non carousel
     * design.
     * 
     * @param {array} cards — empty array that may contain all cards in category after API request.
     * @param {boolean} showAll — sefl state for CardsCarousel class to show all cards or carousel.
     * @param {number} index — index of carousel that comes to zero after activate showAll state.
     * @param {number} screenSize — root prop that shows current screen resolution in number (more in /src/store/app/reducers.js).
     * @param {number} filterOrder — root prop that shows ordering in number (more in /src/store/filter/reducers.js).
     */

    checkCardsLimit() {
        /**
         * Data that is used to slice array in several arrays of cards in
         * equal limit of cards on different screen sizes.
         */
        switch(this.props.screenSize) {
            case 'md':
                return 4;
            case 'lg':
                return 5;
            case 'xl':
                return 6;
            default:
                return 6;
        }
    }

    cardsSliced(array) {
        /**
         * Method that returns filtered and sorted array of cards. Based on app.sort state,
         * filter. Also slices array in some arrays depends on quantity of cards limit
         * based on screen size.
         */
        let outputArray = [];
        // Then slice to arrays for correct carousel
        let arrays = Math.ceil(array.length / this.checkCardsLimit());
        let quantity = this.checkCardsLimit();
        for (let i = 0; i < arrays; i++) {
            outputArray[i] = array.slice((i * quantity), (i * quantity) + quantity);
        }
        // Then return sorted, filtered and sliced array
        return outputArray;
    }

    handleSelect = (selectedIndex, e) => {
        /**
         * Event handler to connect bootstrap react carousel's index.
         */
        this.setState({
            index: selectedIndex
        });
    }

    render() {
        return (
            <div 
                className={`cards-carousel ${this.props.className}`}>
                { !inResponsiveSizes(['xs', 'sm'], this.props.screenSize) ?
                    <Carousel 
                        className={`cards-carousel__carousel slide ${this.cardsSliced(this.props.cards).length > 1 ? '' : 'cards-carousel__carousel--non-carousel'}`}
                        interval={ 1000000 }
                        touch={ true }
                        onSelect={ this.handleSelect }>
                        { this.cardsSliced(this.props.cards).map((cards, index) => (
                            <Carousel.Item
                                key={ index }
                                className="cards-carousel__carousel-item">
                                <div className="cards-carousel__carousel-cards">
                                    <CardsList cards={ cards } />
                                </div>
                            </Carousel.Item>
                        )) }
                    </Carousel>
                    :
                    <div 
                        className="cards-carousel__list cards-carousel__list--mobile">
                        { this.props.cards.map(card => (
                            <Card 
                                className="cards-carousel__card"
                                key={ card.id }
                                card={ card }/>
                        )) }
                    </div>
                }
            </div>
        )
    }
}