import React from 'react';
import FilterHeader from './FilterHeader';
import { connect } from 'react-redux';
import { 
    setShowMoreFilters
} from '../../../store/app/actions';
import { 
    clearFilters
} from '../../../store/filter/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class FilterHeaderContainer extends React.Component {
    render() {
        return (
            <FilterHeader {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showMoreFilters: state.app.showMoreFilters,
        groupesList: state.app.groupesList
    }
}
  
const mapDispatchToProps = {
    setShowMoreFilters,
    clearFilters
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FilterHeaderContainer)