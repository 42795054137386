import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import CardsCarousel from '../CardsCarousel/CardsCarouselContainer';
import CardsList from '../CardsList/CardsListContainer';
import inResponsiveSizes from './../../../lib/ui/inResponsiveSizes';
import './FormatBox.sass'



export default function FormatBox(props) {
    /**
     * Function to show cards list with header includes title and link. 
     * Link is optional.
     * 
     * @param {string} link — link (optional).
     * @param {string} title — title to show.
     * @param {array} cards — cards list.
     */
    return (
        <div className="group-box">
            { props.title &&
                <div className="group-box__header">
                    { props.link ? (
                        <h3
                            className="group-box__header-title">
                            <NavLink
                                className="group-box__header-title-link"
                                to={ props.link }>
                                { props.title }
                            </NavLink>
                        </h3>
                    ) : (
                        <h3
                            className="group-box__header-title">
                            { props.title }
                        </h3>
                    ) }
                    <NavLink
                        className="group-box__quantity"
                        to={ props.link }>
                        { i18next.t('All', { size: props.cards.length }) }
                    </NavLink>
                </div>
            }

            { ((inResponsiveSizes(['xs', 'sm'], props.screenSize) && parseInt(props.listView) === 0) || !inResponsiveSizes(['xs', 'sm'], props.screenSize)) ?
                <CardsCarousel 
                    className="group-box__list"
                    cards={ props.cards }/>
            : 
                <CardsList 
                    cards={ props.cards.slice(0, 6) } />
            }
        </div>
    )
}