import React from 'react';
import i18next from 'i18next';
import Search from './../../common/Search/SearchContainer';
import ClearButton from '../../common/ClearButton/ClearButtonContainer';
import FilterCategories from '../FilterCategories/FilterCategoriesContainer';
import './FilterHeader.sass';



export default function FilterHeader(props) {
    return (
        <div 
            className={`page__filter-row filter-header ${props.showMoreFilters ? 'filter-header--opened' : ''}`}>
            <FilterCategories
                className="filter-header__categories"
                expanded={ props.showMoreFilters } />
            <Search
                className="filter-header__saerch" />
            <button 
                className={`filter-header__toggle btn ${props.showMoreFilters ? 'btn-primary' : 'btn-light'}`}
                onClick={ () => props.setShowMoreFilters(!props.showMoreFilters) }>
                <svg
                    height="20"
                    width="20">
                    <use
                        xlinkHref="svg/bootstrap-icons.svg#arrows-expand" />
                </svg>
            </button>
            <ClearButton />
            { props.showMoreFilters &&
            <div className="filter-header__helper">
                <p>{ i18next.t('By clicking on filters you use them, otherwise if you use search you’ll reach global sarch.') }</p>
            </div>
            }
        </div>
    )
}