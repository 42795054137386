import {
    FILTER_SET_ORDER,
    FILTER_SET_SEARCH,
    FILTER_SET_CATEGORIES,
    FILTER_SEARCH_AREA,
    FILTER_SEARCH_AREAS_AVAILABLE,
    FILTER_CLEAR
} from "./actions";


const defaultState = {
    order: 2,
    /**
     * Order statuses:
     * 0 — default order by repo.sort
     * 1 — order by ASC repo.title
     * 2 — order by repo.created
     * 3 — order first featured in order by repo.sort
     */
    searchString: '',
    categoriesSelected: [],
    searchCurrentArea: 0,
    searchAreas: [0]
    /**
     * Search variants
     * 0 — Global search
     * 1 — Search in filtered
     */
}
  
export const filterReducer = (state = defaultState, action) => {
    switch (action.type) {
        case FILTER_SET_ORDER:
            return { ...state, order: action.payload };

        case FILTER_SET_SEARCH:
            return { ...state, searchString: action.payload };

        case FILTER_SET_CATEGORIES:
            return { ...state, categoriesSelected: action.payload };

        case FILTER_SEARCH_AREAS_AVAILABLE:
            return { ...state, searchAreas: action.payload };

        case FILTER_SEARCH_AREA:
            return { ...state, searchCurrentArea: action.payload };

        case FILTER_CLEAR:
            return { 
                ...state, 
                searchString: '',
                categoriesSelected: action.payload && action.payload.length !== 0 ? action.payload[0].categories.length < 2 ? [action.payload[0].categories[0].id] : state.categoriesSelected : []
             };
    
        default: return state;
    }
}