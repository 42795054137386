import React from 'react';
import ClearButton from './ClearButton';
import { connect } from 'react-redux';
import { 
    clearFilters
} from '../../../store/filter/actions';
import { 
    setMobileNav,
} from './../../../store/app/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class ClearButtonContainer extends React.Component {
    render() {
        return (
            <ClearButton {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        screenSize: state.app.screenSize,
        groupesList: state.app.groupesList,
        searchString: state.filter.searchString,
    }
}
  
const mapDispatchToProps = {
    clearFilters,
    setMobileNav
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ClearButtonContainer)