import React from 'react';
import Category from './Category';
import { connect } from 'react-redux';



/**
 * Middleware to leave stupid component and clever.
 */
class CategoryContainer extends React.Component {
    render() {
        return (
            <Category {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        screenSize: state.app.screenSize,
        shelvesDataLoaded: state.app.shelvesDataLoaded,
        allCardsLoaded: state.app.allCardsLoaded,
        order: state.filter.order
    }
}
  
const mapDispatchToProps = {}
  
export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer)