import React from 'react'
import HomePage from './HomePage';
import { connect } from 'react-redux';
import { setMainView } from '../store/app/actions';
import { 
    setSearchAreas,
    setCurrentCategories
} from '../store/filter/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class HomePageContainer extends React.Component {
    render() {
        return (
            <HomePage {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appDataLoaded: state.app.appDataLoaded,
        shelvesDataLoaded: state.app.shelvesDataLoaded,
        shelves: state.app.shelves,
        heroData: state.app.heroData
    }
}
  
const mapDispatchToProps = {
    setMainView,
    setSearchAreas,
    setCurrentCategories
}
  
export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer)