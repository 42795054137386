import React from 'react';
import i18next from 'i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownLinkButton } from './../../../lib/elements/DropdownLinkButton';
import './Sort.sass';



export default class Sort extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortList: [
                {
                    value: 2,
                    title: i18next.t('Date')
                },
                {
                    value: 1,
                    title: i18next.t('A-Z')
                },
                {
                    value: 3,
                    title: i18next.t('Featured first')
                }
            ]
        };
    }

    setOrderClick(value){
        this.props.setOrder(parseInt(value));
    }

    render() {
        return (
            <div 
                className={`sort ${this.props.className}`}>
                <span 
                    className="sort__order-by">{ i18next.t('Order by') }:</span>
                <Dropdown
                    alignRight>
                    <Dropdown.Toggle 
                        as={DropdownLinkButton}
                        className={ this.props.buttonClassName }>
                        { this.state.sortList.filter(item => item.value === this.props.order )[0].title + ' ' }
                        { this.props.buttonClassName }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    { this.state.sortList.filter(item => item.value !== this.props.order ).map(item => (
                        <Dropdown.Item 
                            eventKey={ item.value }
                            className="sort__dropdown-menu-item dropdown-item"
                            onClick={ () => this.setOrderClick(item.value) }
                            key={ item.value }>{ item.title }</Dropdown.Item>
                    )) }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }
}