import React from 'react';
import i18next from 'i18next';
// import BootstrapModal from 'react-bootstrap/Modal';
import { CSSTransition } from 'react-transition-group';
import './VideoModal.sass';



export default class Modal extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.showModal !== prevProps.showModal) {
            if(this.props.showModal === true) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    }

    handleClose = () => {
        this.props.setOpenModal(false)
    };

    handleShow = () => {
        this.props.setOpenModal(true)
    };

    onAddCardToCollection = (id) =>  {
        this.props.setMyCardsCollection([...this.props.myCardsCollection, id]);
        window.localStorage.setItem("myCardsCollection", JSON.stringify([...this.props.myCardsCollection, id]));
    }

    onRemoveCardFromCollection = (id) =>  {
        let filteredCards = this.props.myCardsCollection.filter(card => card !== id);
        this.props.setMyCardsCollection(filteredCards);
        window.localStorage.setItem("myCardsCollection", JSON.stringify(filteredCards));
    }

    render() {
            return (
            <CSSTransition
                in={ this.props.showModal }
                timeout={ 400 }
                classNames="video-modal"
                unmountOnExit >
                <div 
                    className="video-modal">
                    <button 
                        className="video-modal__close-button"
                        onClick={ () => this.handleClose() }>&times;</button>
                    <div className="video-modal__frame">
                        <iframe 
                            src={ this.props.modalData.link }
                            width="640" 
                            height="360" 
                            frameBorder="0" 
                            allow="autoplay; fullscreen" 
                            allowFullScreen 
                            title="video" />
                    </div>
                    <div className="video-modal__title">
                        <h1>
                            { this.props.modalData.portal_title }
                        </h1>
                        { this.props.modalData.is_recommendation === 1 && 
                        <span 
                            className="flip-card__icon flip-card__icon--featured"
                            title={ i18next.t('Featured') }>
                            <svg
                                class="video-modal__recomendation-icon"
                                height="16"
                                width="16">
                                <use
                                    xlinkHref="/svg/bootstrap-icons.svg#star-fill" />
                            </svg>
                            { i18next.t('Featured') }
                        </span>
                        }
                    </div>
                    <div className="video-modal__buttons">
                    { this.props.myCardsCollection.includes(this.props.modalData.id) ? 
                        <button 
                            className="btn btn-danger"
                            title={ i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                            onClick={ () => this.onRemoveCardFromCollection(this.props.modalData.id) }>
                            { i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                        </button> :
                        <button 
                            className="btn btn-primary"
                            title={ i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                            onClick={ () => this.onAddCardToCollection(this.props.modalData.id) }>
                            { i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                        </button>
                    }
                    </div>
                    <div 
                        className="video-modal__description">
                        <div dangerouslySetInnerHTML={{__html: this.props.modalData.content }} />
                    </div>
                </div>
            </CSSTransition>
        )
    }
}