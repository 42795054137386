import React from 'react';
import Default from './Default';
import { connect } from 'react-redux';



/**
 * Middleware to leave stupid component and clever.
 */
class DefaultContainer extends React.Component {
    render() {
        return (
            <Default {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        screenSize: state.app.screenSize,
        order: state.filter.order
    }
}
  
const mapDispatchToProps = {}
  
export default connect(mapStateToProps, mapDispatchToProps)(DefaultContainer)