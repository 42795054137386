import React from 'react';
import { NavLink } from 'react-router-dom';
import './Logo.sass';



export default function Logo(props) {
    return (
        <NavLink 
            className={`logo ${props.class}`}
            to="/"
            onClick={ () => props.clearFilters(props.groupesList) }>
            <span className="logo__brand">
                <span>{ props.brandName }</span>
            </span>
        </NavLink>
    )
}