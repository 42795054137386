import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import CategoriesCheckboxList from './../../common/CategoriesCheckboxList/CategoriesCheckboxListContainer';
import { CategoryDropdownButton } from './../../../lib/elements/CategoryDropdownButton';
import './FilterCategories.sass';


export default class FilterCategories extends React.Component {
    selectedCounter(array) {
        let compare = [];
        let ids = array.map(item => item.id);
        for(let i in this.props.categoriesSelected) {
            if(ids.indexOf(this.props.categoriesSelected[i]) > -1){
                compare.push(this.props.categoriesSelected[i]);
            }
        }
        return compare.length;
    }

    render() {
        return (
            <div
                className={`filter-categories ${ this.props.className }`}
                role="group">
                { this.props.groupesList.map(group => {
                    if(group.categories.length !== 1) {
                        if( !this.props.expanded ) {
                            return (
                                <Dropdown
                                    className="filter-categories__category dropdown btn-group"
                                    as={ ButtonGroup }
                                    key={ group.id }>
                                    <Dropdown.Toggle  
                                        as={ CategoryDropdownButton }>
                                        { group.title }
                                        { this.selectedCounter(group.categories)  >= 1 &&
                                            <span className="filter-categories__category-counter">
                                                { this.selectedCounter(group.categories) }
                                            </span>
                                        }
                                    </Dropdown.Toggle>
    
                                
                                    <Dropdown.Menu 
                                        className="filter-categories__dropdown-menu">
                                        <CategoriesCheckboxList
                                            categories={ group.categories } />
                                    </Dropdown.Menu>
                                </Dropdown>
                            )
                        } else {
                            return (
                                <div 
                                    className="filter-categories__category"
                                    key={ group.id }>
                                    <span className="filter-categories__category-title">{ group.title }</span>
                                    <CategoriesCheckboxList
                                        categories={ group.categories } />
                                </div>
                            )
                        }
                    } else {
                        return false;
                    }
                }
            )}
            </div>
        )
    }
}