import React from 'react';
import Filter from './Filter';
import { connect } from 'react-redux';



/**
 * Middleware to leave stupid component and clever.
 */
class FilterContainer extends React.Component {
    render() {
        return (
            <Filter {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        screenSize: state.app.screenSize,
        cardsList: state.app.cardsList,
        allCardsLoaded: state.app.allCardsLoaded,
        filterOrder: state.filter.order,
        formats: state.app.formats,
        groupesList: state.app.groupesList,
        categoriesSelected: state.filter.categoriesSelected
    }
}
  
const mapDispatchToProps = {}
  
export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer)