export default function shareLinkGenerator(array) {
    let protocol = window.location.protocol;
    let slashes = protocol.concat("//");
    let host = slashes.concat(window.location.hostname);

    let url = new URL(`${host + (window.location.port ? ':'+window.location.port: '')}/shared`);
    for(let card of array){
        url.searchParams.append('card', card);
    };

    return url;
}