import React from 'react';
import i18next from 'i18next';
import './RowCard.sass';



export default class RowCard extends React.Component {
    /**
     * Class RowCard gets few props like card and draws a card. Simple card.
     * 
     * @param {object} cards — object contains all card params like title.
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    onCallModalHandler = (e) => {
        /** 
         * Call modal window if card is video-card.
         */
        e.preventDefault();
        this.props.setOpenModal(true);
        this.props.setModalData({...this.props.card});
    }

    onAddCardToCollection = () =>  {
        this.props.setMyCardsCollection([...this.props.myCardsCollection, this.props.card.id]);
        window.localStorage.setItem("myCardsCollection", JSON.stringify([...this.props.myCardsCollection, this.props.card.id]));
    }

    onRemoveCardFromCollection = () =>  {
        let filteredCards = this.props.myCardsCollection.filter(card => card !== this.props.card.id);
        this.props.setMyCardsCollection(filteredCards);
        window.localStorage.setItem("myCardsCollection", JSON.stringify(filteredCards));
    }

    render(){
        const { 
            id,
            media_cover, 
            portal_title, 
            is_recommendation,
            is_withdrawn,
            link,
            type } = this.props.card;
        return (
            <div
                className={ ` row-card ${ this.props.className } ${ type ? 'row-card--' + type.type : '' }` }
                title={ portal_title }>
                <div className="row-card__image-area">
                    <a 
                        href={ is_withdrawn === null || is_withdrawn === false ? link : false }
                        target={ is_withdrawn === null || is_withdrawn === false ? '_blank' : false }
                        onClick={ type ? type.type === 'video' ? this.onCallModalHandler : () => false : () => false }>
                        { media_cover ? (
                            <img 
                                className="row-card__image"
                                data-id={ id }
                                src={ media_cover }
                                alt={ portal_title }/>
                        ) : (
                            <span>No image</span>
                        )}
                    </a>
                </div>
                <a 
                    className="row-card__info"
                    href={ is_withdrawn === null || is_withdrawn === false ? link : false }
                    target={ is_withdrawn === null || is_withdrawn === false ? '_blank' : false }
                    onClick={ type ? type.type === 'video' ? this.onCallModalHandler : () => false : () => false }>
                    <span 
                        className="row-card__title">
                        { portal_title }
                    </span>
                    { (is_recommendation === 1 || type) &&
                        <div
                            className="row-card__subtitle">
                            { is_recommendation === 1 && 
                                <span
                                    className="row-card__type row-card__type--featured">
                                    { i18next.t('Featured') }
                                </span>
                            }
                            { type &&
                                <span 
                                    className="row-card__type">
                                    { type.title }
                                </span>
                            }
                        </div>
                    }
                </a>
                {
                    this.props.myCardsCollection.includes(id) ? 
                    <button 
                        className="row-card__button row-card__button--remove-from-favorites"
                        title={ i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                        onClick={ this.onRemoveCardFromCollection }><span>-</span></button> :
                    <button 
                        className="row-card__button row-card__button--add-to-favorites"
                        title={ i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                        onClick={ this.onAddCardToCollection }><span>+</span></button>
                }
            </div>
        )
    }
}