import React from 'react';
import VideoCard from './VideoCard';
import FlipCard from './FlipCard';



export default function Card(props) {
    /**
     * Class Card gets few props like card and draws a card. Simple card.
     * 
     * @param {object} cards — object contains all card params like title.
     */

    function onAddCardToCollection() {
        props.setMyCardsCollection([...props.myCardsCollection, props.card.id]);
        window.localStorage.setItem("myCardsCollection", JSON.stringify([...props.myCardsCollection, props.card.id]));
    }

    function onRemoveCardFromCollection() {
        let filteredCards = props.myCardsCollection.filter(card => card !== props.card.id);
        props.setMyCardsCollection(filteredCards);
        window.localStorage.setItem("myCardsCollection", JSON.stringify(filteredCards));
    }

    if(props.card.type && props.card.type.type === 'video')
    {
        return (
            <VideoCard
                { ...props.card }
                className={ props.className }
                myCardsCollection={ props.myCardsCollection }
                showModal={ props.showModal }
                setOpenModal={ props.setOpenModal }
                setModalData={ props.setModalData }
                setMyCardsCollection={ props.setMyCardsCollection }
                addCard={ onAddCardToCollection }
                removeCard={ onRemoveCardFromCollection }
                heroCard={ props.heroCard }/>
        )
    } else {
        return (
            <FlipCard
                { ...props.card }
                className={ props.className }
                myCardsCollection={ props.myCardsCollection }
                setMyCardsCollection={ props.setMyCardsCollection }
                addCard={ onAddCardToCollection }
                removeCard={ onRemoveCardFromCollection }
                heroCard={ props.heroCard } />
        )
    }
}