import React, { useState } from 'react';
import i18next from 'i18next';
import './FlipCard.sass';



export default function FlipCard(props) {
    const [flip, setFlip] = useState(null);

    function CheckCardType(props) {
        if (!props.type || props.type.id === 31 || props.type.id === 18) {
            return null;
        }
        return (
            <span className={props.classes}>{ props.type.title }</span>
        );
    }

    return (
        <div
            className={`flip-card ${flip === true ? 'flip-card--flipped' : ''} ${ props.className }`}
            title={ props.portal_title }>
            <div className="flip-card__image-area">
                <div 
                    className="flip-card__flip-area"
                    onClick={ () => setFlip(!flip) }>
                    <div
                        className="flip-card__flip-side flip-card__flip-side--front flip-card__image-area-link">
                        { props.media_cover ? (
                            <img 
                                className="flip-card__image"
                                data-id={ props.id }
                                src={ props.media_cover }
                                alt={ props.portal_title }/>
                        ) : (
                            <span>No image</span>
                        )}
                    </div>
                    <div className="flip-card__flip-side flip-card__flip-side--back">
                        <CheckCardType type={props.type} classes={'flip-card__type mb-2'} />
                        <h4 className="flip-card__title">{ props.portal_title }</h4>
                        <a  
                            className="mt-auto"
                            href={ props.is_withdrawn === null || props.is_withdrawn === false ? props.link : 'undefined' }
                            target={ props.is_withdrawn === null || props.is_withdrawn === false ? '_blank' : 'undefined' }>
                            { i18next.t('Show document') }
                        </a>
                    </div>
                </div>
                {
                    props.myCardsCollection.includes(props.id) ? 
                    <button 
                        className="flip-card__button flip-card__button--remove-from-favorites"
                        title={ i18next.t('Toggle favorites card', { do: i18next.t('Remove from') }) }
                        onClick={ () => props.removeCard() }><span>&minus;</span></button> :
                    <button 
                        className="flip-card__button flip-card__button--add-to-favorites"
                        title={ i18next.t('Toggle favorites card', { do: i18next.t('Add to') }) }
                        onClick={ () => props.addCard() }><span>&#43;</span></button>
                }
                { props.is_recommendation === 1 && 
                <span 
                    className="flip-card__icon flip-card__icon--featured"
                    title={ i18next.t('Featured') }>
                    <svg
                        height="20"
                        width="20">
                        <use
                            xlinkHref="/svg/bootstrap-icons.svg#star-fill" />
                    </svg>
                </span>
                }
                <CheckCardType type={props.type} classes={'flip-card__type flip-card__type--hide-flip'} />
            </div>
            { !props.heroCard &&
                <div
                    className="flip-card__info"
                    onClick={ () => setFlip(!flip) }>
                    <span 
                        className="flip-card__title flip-card__title--cutted">
                        { props.portal_title }
                    </span>
                </div>
            }
        </div>
    )
}