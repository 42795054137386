import React from 'react';
import i18next from 'i18next';
import CategoriesCheckboxList from './../../common/CategoriesCheckboxList/CategoriesCheckboxListContainer';
import ClearButton from '../../common/ClearButton/ClearButtonContainer';
import './FilterCategories.sass';



export default function FilterCategories(props) {
    return (
        <div className="mobile-category-filter">
            <h2 className="mobile-category-filter__title">{ i18next.t('Filtering') }</h2>
            <div className="mobile-category-filter__buttons">
                <ClearButton />
                <button 
                    className="btn btn-primary ml-2"
                    onClick={ () => props.setMobileNav(0) }>
                    { i18next.t('Apply') }
                </button>
            </div>
            <div className="mobile-category-filter__categories">
                { props.groupesList.map((group, index) => {
                    if(group.categories.length !== 1) {
                        return (
                            <div 
                                className="mobile-category-filter__category"
                                key={ index }>
                                <h5
                                    className="mobile-category-filter__category-title">{ group.title }</h5>
                                <CategoriesCheckboxList
                                    categories={ group.categories } />
                            </div>
                        )
                    } else {
                        return false;
                    }
                }) }
            </div>
        </div>
    )
}