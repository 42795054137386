import React from 'react';
import {
    BrowserRouter, 
    Route, 
    Switch
} from "react-router-dom";
import './i18n/i18n';
import 'font-awesome/css/font-awesome.min.css';
import './styles/app.sass';
import HomePage from './pages/HomePageContainer';
import SharedPage from './pages/SharedPageContainer';
import FilterPage from './pages/FilterPageContainer';
import SearchPage from './pages/SearchPageContainer';
import ImprintPage from './pages/ImprintPage';
import DisclaimerPage from './pages/DisclaimerPage';
import BarrierefreiPage from './pages/BarrierefreiPage';
import CollectionPage from './pages/CollectionPageContainer';
import NoMatchPage from './pages/NoMatchPage';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import checkResolution from './lib/ui/checkResolution';
import VideoModal from './components/common/VideoModal/VideoModalContainer';
import CookiesMessage from './components/common/CookiesMessage/CookiesMessage';
import runMatomo from './lib/matomo/runMatomo';



export function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}

export default class App extends React.Component {
    componentDidMount() {
        // Get user cards collection from local storage.
        let cards = JSON.parse(localStorage.getItem("myCardsCollection")) ? JSON.parse(localStorage.getItem("myCardsCollection")) : [];
        this.props.setMyCardsCollection(cards);

        // Get App data from API through redux saga.
        this.props.getAppData();

        // Check current resolution on App mounted.
        this.props.setScreenSize(checkResolution());
        // And set watcher to get actual state of screen sze,
        window.addEventListener('resize', () => {
            this.props.setScreenSize(checkResolution());
        })

        // Check cookies accepted
        let checkRunMatomo = JSON.parse(localStorage.getItem("cookiesAccepted")) ? JSON.parse(localStorage.getItem("cookiesAccepted")) : false;
        if(checkRunMatomo) {
            runMatomo();
        }

        this.props.setListView(JSON.parse(localStorage.getItem("listView")) ? JSON.parse(localStorage.getItem("listView")) : 0);
    }

    render() {
        return (
            <BrowserRouter className="App">
                <ScrollToTop />
                <div className="page__screensize-wrapper">
                    {/* <Header></Header> */}
                    <Switch>
                        <Route path="/" exact>
                            <HomePage />
                        </Route>
                        <Route path="/library">
                            <FilterPage />
                        </Route>
                        <Route path="/search">
                            <SearchPage />
                        </Route>
                        <Route path="/shared">
                            <SharedPage /> 
                        </Route>
                        <Route path="/impressum">
                            <ImprintPage/>
                        </Route>
                        <Route path="/datenschutz">
                            <DisclaimerPage />
                        </Route>
                        <Route path="/barrierefreiheit">
                            <BarrierefreiPage />
                        </Route>
                        <Route path="/collection">
                            <CollectionPage />
                        </Route>
                        <Route path="*">
                            <NoMatchPage />
                        </Route>
                    </Switch>
                </div>
                <VideoModal />
                <CookiesMessage />
            </BrowserRouter>
        )
    }
}
