import React from 'react';
import i18next from 'i18next';
import {NavLink} from 'react-router-dom';
import DefaultLayout from './../layouts/Default';
import {
    clearFilters
} from '../store/filter/actions';


export default function DisclaimerPage(props) {

    return (
        <DefaultLayout
            content={
                <div className="page__content container mt-4">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <NavLink
                                className="link"
                                to={`/`}
                                onClick={() => clearFilters()}>
                                {i18next.t('Home')}
                            </NavLink>
                            <h1 className="mb-4">Datenschutz</h1>

                            <h2 className="my-4">1. Information über die Erhebung personenbezogener Daten</h2>
                            <p>(1) Im Folgenden informieren wir über die Erhebung personenbezogener Daten
                                bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten, die
                                auf Sie persönlich beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen,
                                Nutzerverhalten.</p>
                            <p>(2) Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung
                                (DSGVO) für die Verarbeitung von personenbezogenen Daten auf dieser
                                Website ist</p>
                            <p>Unfallkasse Nordrhein-Westfalen (UK NRW) Moskauer Straße 18
                                40227 Düsseldorf</p>
                            <p>Der Datenschutzbeauftragte ist unter der o.g. Anschrift beziehungsweise unter
                                datenschutz@unfallkasse-nrw.de erreichbar.</p>

                            <p>(3) Bei Ihrer Kontaktaufnahme mit uns werden die von Ihnen mitgeteilten
                                Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer sowie
                                ggf. weitere Informationen) von uns gespeichert, um Ihre Fragen zu
                                beantworten. Die Speicherung erfolgt in der Regel gem. Art. 6 Abs. 1
                                Ziff. c bzw. e DSGVO und dient einer rechtlichen Verpflichtung bzw. der
                                Wahrnehmung von gesetzlichen Aufgaben der Unfallkasse NRW nach § 199 SGB
                                VII.</p>
                            <p>(4) Die UK NRW ist u.a. auch per E-Mail erreichbar. Jedoch
                                ist eine Datenübertragung per E-Mail derzeit unsicher und daher vor
                                allem für allgemeine Anfragen geeignet. Es kann nicht ausgeschlossen
                                werden, dass auf diesem Wege übermittelte Daten von Unbefugten gelesen,
                                kopiert, verändert oder gelöscht werden. Wir antworten per E-Mail, wenn
                                Sie Ihre E-Mail-Adresse eingetragen haben. Sofern Sozialdaten betroffen
                                sind, dürfen wir Ihre Anfragen nicht per E-Mail beantworten, sondern
                                nutzen weiterhin den Postweg. Nur auf diese Weise sind die Authentizität
                                und die Vertraulichkeit sichergestellt. Wir empfehlen Ihnen,
                                Sozialdaten oder andere vertrauliche Informationen nicht per E-Mail zu
                                senden.</p>
                            <p>(4) Die UK NRW ist u.a. auch per E-Mail erreichbar. Jedoch
                                ist eine Datenübertragung per E-Mail derzeit unsicher und daher vor
                                allem für allgemeine Anfragen geeignet. Es kann nicht ausgeschlossen
                                werden, dass auf diesem Wege übermittelte Daten von Unbefugten gelesen,
                                kopiert, verändert oder gelöscht werden. Wir antworten per E-Mail, wenn
                                Sie Ihre E-Mail-Adresse eingetragen haben. Sofern Sozialdaten betroffen
                                sind, dürfen wir Ihre Anfragen nicht per E-Mail beantworten, sondern
                                nutzen weiterhin den Postweg. Nur auf diese Weise sind die Authentizität
                                und die Vertraulichkeit sichergestellt. Wir empfehlen Ihnen,
                                Sozialdaten oder andere vertrauliche Informationen nicht per E-Mail zu
                                senden.</p>

                            <h2 className="my-4">2. Ihre Rechte</h2>
                            <p>(1) Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden
                                personenbezogenen Daten:</p>
                            <ul>
                                <li>Recht auf Auskunft Art. 15 DSGVO</li>
                                <li>Recht auf Berichtigung oder Löschung Art. 16 DSGVO Art. 17 DSGVO</li>
                                <li>Recht auf Einschränkung der Verarbeitung Art. 18 DSGVO</li>
                                <li>Recht auf Widerspruch gegen die Verarbeitung Art. 21 DSGVO</li>
                                <li> Recht auf Datenübertragbarkeit Art. 20 DSGVO</li>
                            </ul>

                            <p>(2) Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde
                                z.B. der Landesbeauftragten für Datenschutz in NRW über die Verarbeitung
                                Ihrer personenbezogenen Daten durch uns zu beschweren.</p>

                            <h2 className="my-4">3. Erhebung personenbezogener Daten bei Besuch unserer Website</h2>
                            <p>(1) Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich
                                nicht registrieren oder uns anderweitig Informationen übermitteln,
                                erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren
                                Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben
                                wir die folgenden Daten, die für uns technisch erforderlich sind, um
                                Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu
                                gewährleisten. Rechtsgrundlage hierfür ist eine Interessenabwägung nach
                                Art. 6 Abs. 1 S. 1 lit. f DSGVO):</p>
                            <ul>
                                <li>IP-Adresse</li>
                                <li>Datum und Uhrzeit der Anfrage</li>
                                <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                                <li>Inhalt der Anforderung (konkrete Seite)</li>
                                <li>Zugriffsstatus/HTTP-Statuscode</li>
                                <li>jeweils übertragene Datenmenge</li>
                                <li>Website, von der die Anforderung kommt</li>
                                <li>Browser</li>
                                <li>Betriebssystem und dessen Oberfläche</li>
                                <li>Sprache und Version der Browsersoftware.</li>
                            </ul>

                            <p>(2) Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung
                                unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies
                                handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von
                                Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche
                                der Stelle, die den Cookie setzt (hier durch uns), bestimmte
                                Informationen zufließen. Cookies können keine Programme ausführen oder
                                Viren auf Ihren Computer übertragen. Sie dienen dazu, das
                                Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.</p>

                            <p>(3) Einsatz von Cookies:</p>
                            <p>a) Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im
                                Folgenden erläutert werden:</p>
                            <ul>
                                <li>Transiente Cookies (dazu b)</li>
                                <li>Persistente Cookies (dazu c).</li>
                            </ul>

                            <p>b) Transiente Cookies werden
                                automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen
                                insbesondere die Session-Cookies. Diese speichern eine sogenannte
                                Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der
                                gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner
                                wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die
                                Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den
                                Browser schließen.</p>
                            <p>c) Persistente Cookies werden automatisiert
                                nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie
                                unterscheiden kann. Sie können die Cookies in den
                                Sicherheitseinstellungen Ihres Browsers jederzeit löschen.</p>
                            <p>c) Persistente Cookies werden automatisiert
                                nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie
                                unterscheiden kann. Sie können die Cookies in den
                                Sicherheitseinstellungen Ihres Browsers jederzeit löschen.</p>
                            <h2 className="my-4">4. Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</h2>
                            <p>(1) Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben,
                                können Sie diese jederzeit widerrufen. Ein solcher Widerruf wirkt für
                                die zukünftige Verarbeitung nach Ausspruch des Widerrufs.</p>
                            <p>(2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die
                                Rechtsgrundlage Art. 6 Abs. 1 lit. f DSGVO (Interessenabwägung) stützen,
                                können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der
                                Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines
                                Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der
                                nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung
                                eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb
                                wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt
                                verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir
                                die Sachlage und werden entweder die Datenverarbeitung einstellen bzw.
                                anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen,
                                aufgrund derer wir die Verarbeitung fortführen.</p>

                            <h2 className="my-4">5. Einsatz von Matomo (ehemals Piwik)</h2>
                            <p>(1) Diese Website nutzt den Webanalysedienst Matomo (ehemals Piwik), um die
                                Nutzung unserer Website analysieren und regelmäßig verbessern zu
                                können. Über die gewonnenen Statistiken können wir unser Angebot
                                verbessern und für Sie als Nutzer interessanter ausgestalten.
                                Rechtsgrundlage für die Nutzung von Matomo ist Art. 6 Abs. 1 S. 1 lit. f
                                DSGVO (Interessenabwägung). Es liegt in unserem überwiegenden
                                Interesse, unsere Angebote gegenüber unserem Kunden verbessern zu
                                können. Als Betreiber dieser Website haben wir ein berechtigtes
                                Interesse an der anonymisierten Analyse des Nutzerverhaltens, um unser
                                Webangebot zu optimieren.</p>

                            <p>(2) Für diese Auswertung werden Cookies
                                (näheres dazu in § 3) auf Ihrem Computer gespeichert. Die so erhobenen
                                Informationen speichert der Verantwortliche ausschließlich auf seinem
                                Server in Deutschland. Die Auswertung können Sie einstellen durch
                                Löschung vorhandener Cookies und die Verhinderung der Speicherung von
                                Cookies. Wenn Sie die Speicherung der Cookies verhindern, weisen wir
                                darauf hin, dass Sie gegebenenfalls diese Website nicht vollumfänglich
                                nutzen können. Die Verhinderung der Speicherung von Cookies ist durch
                                die Einstellung in ihrem Browser möglich. Die Verhinderung des Einsatzes
                                von Matomo ist möglich, indem Sie den folgenden Haken entfernen und so
                                das Opt-out-Plug-in aktivieren:</p>
                            <p>(3) Diese Website verwendet Matomo mit der Erweiterung „AnonymizeIP“.
                                Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine direkte
                                Personenbeziehbarkeit kann damit ausgeschlossen werden. Die mittels
                                Matomo von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
                                von uns erhobenen Daten zusammengeführt.</p>

                            <p>(4) Das Programm Matomo ist ein Open-Source-Projekt. Informationen des Drittanbieters zum
                                Datenschutz erhalten Sie unter <a href="https://matomo.org/privacy-policy/"
                                                                  target="_blank" rel="noopener noreferrer">https://matomo.org/privacy-policy/</a>
                            </p>

                            <p>(5) Die erhobenen Analyse- und Statistikdaten (anonymisiert) über die
                                Nutzung unserer Webseite werden maximal 18 Monate aufbewahrt und
                                anschließend gelöscht.“</p>
                            <h2 className="my-4">6. Nutzung von Vimeo-Plugins</h2>
                            <p>Wir nutzen für die Einbindung von Videos u.a. den Anbieter Vimeo. Vimeo
                                wird betrieben von Vimeo, LLC mit Hauptsitz in 555 West 18th Street, New
                                York, New York 10011.</p>
                            <p>Auf einigen unserer Internetseiten
                                verwenden wir Plugins des Anbieters Vimeo. Rechtsgrundlage ist hierfür
                                der Art. 6 Abs. 1 lit f (Interessenabwägung). Es liegt in unserem
                                überwiegenden Interessen, Ihnen unser Leistungsangebot auf unserer
                                Website darstellen zu können. Wenn Sie die mit einem solchen Plugin
                                versehenen Internetseiten unserer Internetpräsenz aufrufen –
                                beispielsweise unsere Mediathek –, wird eine Verbindung zu den
                                Vimeo-Servern hergestellt und dabei das Plugin dargestellt. Hierdurch
                                wird an den Vimeo-Server übermittelt, welche unserer Internetseiten Sie
                                besucht haben. Sind Sie dabei als Mitglied bei Vimeo eingeloggt, ordnet
                                Vimeo diese Information Ihrem persönlichen Benutzerkonto zu. Bei Nutzung
                                des Plugins wie z.B. Anklicken des Start-Buttons eines Videos wird
                                diese Information ebenfalls Ihrem Benutzerkonto zugeordnet. Sie können
                                diese Zuordnung verhindern, indem Sie sich vor der Nutzung unserer
                                Internetseite aus ihrem Vimeo-Benutzerkonto abmelden und die
                                entsprechenden Cookies von Vimeo löschen.</p>
                            <p>Zweck und Umfang der
                                Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch
                                Vimeo sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten
                                zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den
                                Datenschutzhinweisen von Vimeo: <a href="https://vimeo.com/privacy"
                                                                   target="_blank" rel="noopener noreferrer">https://vimeo.com/privacy</a></p>

                            <h2 className="my-4">7. Einbindung von YouTube-Videos</h2>

                            <p>(1) Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die auf <a href="http://www.YouTube.com"
                                                                                                            target="_blank" rel="noopener noreferrer">
                                http://www.youtube.com/</a> gespeichert sind und von 
                                unserer Website aus direkt abspielbar sind. Diese sind alle im „erweiterten 
                                Datenschutz-Modus“ eingebunden, d.h. dass keine Daten über Sie als Nutzer an 
                                YouTube übertragen werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie
                                die Videos abspielen, werden die in Absatz 2 genannten Daten übertragen. Auf
                                diese Datenübertragung haben wir keinen Einfluss.
                            </p>
                            
                            <p>(2) Durch den Besuch auf der Website erhält YouTube die Information, dass 
                                Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden 
                                die unter § 3 dieser Erklärung genannten Daten übermittelt. Dies erfolgt unabhängig 
                                davon, ob YouTube ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder 
                                ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten 
                                direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube 
                                nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. YouTube 
                                speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, 
                                Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche 
                                Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung 
                                von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre 
                                Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu 
                                gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube richten müssen.
                            </p>

                            <p>(3) Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung 
                                durch YouTube erhalten Sie in der Datenschutzerklärung. Dort erhalten Sie auch weitere 
                                Informationen zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer 
                                Privatsphäre: <a href="https://www.google.de/intl/de/policies/privacy"
                                                 target="_blank" rel="noopener noreferrer">https://www.google.de/intl/de/policies/privacy</a>. 
                                Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield 
                                unterworfen, <a href="https://www.privacyshield.gov/EU-US-Framework"
                                                target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.
                            </p>
                        </div>
                    </div>
                </div>
            }>
        </DefaultLayout>
    )
}