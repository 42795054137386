import React from 'react'
import RowCard from './RowCard'
import { connect } from 'react-redux';
import { 
    setOpenModal,
    setModalData
} from './../../../store/app/actions';
import { setMyCardsCollection } from './../../../store/share/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class RowCardContainer extends React.Component {
    render() {
        return (
            <RowCard {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showModal: state.app.showModal,
        screenSize: state.app.modalContent,
        myCardsCollection: state.share.myCardsCollection
    }
}
  
const mapDispatchToProps = {
    setOpenModal,
    setModalData,
    setMyCardsCollection
}
  
export default connect(mapStateToProps, mapDispatchToProps)(RowCardContainer)