
import CookieConsent from "react-cookie-consent";
import React from 'react';
import i18next from 'i18next';
import runMatomo from "../../../lib/matomo/runMatomo";
import './CookiesMessage.sass';


export default function CookiesMessage() {
    return (
        <CookieConsent
            location="bottom"
            buttonText="OK"
            declineButtonText={ i18next.t('I decline' ) }
            cookieName="cookiesAccepted"
            enableDeclineButton={ true }
            disableStyles={ true }
            onAccept={() => {
                localStorage.setItem('cookiesAccepted', JSON.stringify(true));
                runMatomo();
            }}
            onDecline={() => {
                localStorage.setItem('cookiesAccepted', JSON.stringify(false));
            }}>
            { i18next.t('Accept agreement of cookies' ) }

            <a
                href="/disclaimer">
                { i18next.t('Disclaimer' ) }
            </a>
        </CookieConsent>
    )
}