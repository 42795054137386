import React from 'react'
import FilterPage from './FilterPage';
import { connect } from 'react-redux';
import { setMainView } from '../store/app/actions';
import { 
    setSearchAreas,
    setCurrentCategories
} from '../store/filter/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class FilterPageContainer extends React.Component {
    render() {
        return (
            <FilterPage {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        groupesList: state.app.groupesList,
        formats: state.app.formats,
        cardsList: state.app.cardsList,
        filterOrder: state.app.filterOrder,
        allCardsLoaded: state.app.allCardsLoaded,
        categoriesSelected: state.filter.categoriesSelected
    }
}
  
const mapDispatchToProps = {
    setMainView,
    setSearchAreas,
    setCurrentCategories
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FilterPageContainer)