import React from 'react';
import Filter from './FilterCategories';
import { connect } from 'react-redux';
import { 
    setMobileNav,
} from './../../../store/app/actions';



/**
 * Middleware to leave stupid component and clever.
 */
class FilterContainer extends React.Component {
    render() {
        return (
            <Filter {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        categoriesSelected: state.filter.categoriesSelected,
        groupesList: state.app.groupesList
    }
}
  
const mapDispatchToProps = {
    setMobileNav
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer)