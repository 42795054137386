import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import './Footer.sass';
import data from './../../../../package.json';

const currentYear = new Date().getFullYear();

export default class Footer extends React.Component {
    render() {
        return (
            <footer
                className="page__footer footer">
                <nav className="footer__links">
                    <ul className="nav flex-column flex-sm-row">
                        <li 
                            className="nav-item">
                            <NavLink 
                                className="nav-link"
                                to="/datenschutz">{ i18next.t('Link disclaimer') }</NavLink>
                        </li>
                        <li 
                            className="nav-item">
                            <NavLink 
                                className="nav-link"
                                to="/impressum">{ i18next.t('Link imprint') }</NavLink>
                        </li>
                        <li
                            className="nav-item">
                            <NavLink
                                className="nav-link"
                                to="/barrierefreiheit">{ i18next.t('Accessibility') }</NavLink>
                        </li>
                    </ul>
                </nav>
                <div className="footer__copyright">
                    <span>&copy; {currentYear} — { i18next.t('Portal') } <small>v.{ data.version }</small></span>
                </div>
            </footer>
        )
    }
}